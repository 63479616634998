<template>
  <div>
    <AppTable
      :rows="orders.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: orders.limit,
        count: orders.count,
        page: orders.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Direction" label-for="type-select">
              <v-select
                id="type-select"
                v-model="type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Direction"
                :options="typeOptions"
                :reduce="option => option.value"
                @input="changeType"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" xl="4" class="mb-1">
            <AssetFilter v-model="selectCoin" label="Crypto" placeholder="Select crypto" @change="changeCoin" />
          </b-col>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Fiat" label-for="select-fiat">
              <v-select
                id="select-fiat"
                v-model="selectFiat"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select fiat"
                :options="fiatCurrency.value"
                :reduce="option => option.id"
                @input="changeFiat"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Order type" label-for="type-order">
              <v-select
                id="type-order"
                v-model="typeOrder"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select order"
                :options="typeOrderOptions"
                :reduce="option => option.value"
                @input="changeOrder"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" xl="4" class="mb-1">
            <b-form-group label="Status" label-for="status-active">
              <v-select
                id="status-active"
                v-model="selectStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select status"
                :options="statusOptions"
                :reduce="option => option.value"
                @input="changeStatus"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Buyer/Seller email" type="text" />
            </b-form-group>
          </b-col>

          <b-col md="1" xl="1" class="mb-1 mt-auto pb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

        <span v-if="column.field === 'user'" class="flex-center-align">
          <b-button v-if="row.user.firstName || row.user.lastName"  variant="link" @click="toUser(row.user.id)" target="_blank" class="text-left">
            {{ `${row.user.firstName || ''} ${row.user.lastName || ''}` }}
          </b-button>
          <b-button v-else  variant="link" @click="toUser(row.user.id)" target="_blank" class="text-left">
            {{ row.user.telegram_username || row.user.name }}
          </b-button>
        </span>

        <span v-else-if="column.field === 'coin'" class="flex-center-align">
          <img :src="row.crypto.image" alt="coin" class="coin-img">
        </span>

        <span v-else-if="column.field === 'fiatAmount'" class="flex-center-align">
          {{ `${$decimal(row.fiatAmount).toDecimalPlaces(2)} ${row.fiat.code}` }}
        </span>

        <span v-else-if="column.field === 'price'" class="flex-center-align">
          {{ `${$decimal(row.price).toDecimalPlaces(2)} ${row.fiat.code}` }}
        </span>

        <span v-else-if="column.field === 'cryptoAmount'" class="flex-center-align">
          {{ `${$decimal(row.available).toDecimalPlaces(8)} ${row.crypto.symbol}` }}
        </span>

        <span v-else-if="column.field === 'limits'" class="flex-center-align">
          {{ `${row.minLimit} ${row.crypto.symbol} - ${row.maxLimit} ${row.crypto.symbol}` }}
        </span>

        <span v-else-if="column.field === 'paymentMethod'" class="flex-center-align">
          {{ row.paymentMethod.name }}
        </span>

        <span v-else-if="column.field === 'status'" class="flex-center-align">
          <b-badge :variant="getStatusColor(row.status)" class="text-white">
            {{ getStatusName(row.status) || '' }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'countDeals'" class="flex-center-align">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            @click="toDeals(row)"
          >
            {{ row.countDeals }}
          </b-button>
          <!-- <div class="link" @click="toDeals(row.id)">link - {{ row.countDeals }}</div> -->
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'
import vSelect from 'vue-select'
import AssetFilter from '@/components/containers/AssetFilter.vue'
import { typeOptions, typeOrderOptions, statusOptions } from './config/selectOptions'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BBadge,
    AppTable,
    TableRow,
    vSelect,
    AssetFilter,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      searchTerm: '',
      dateFrom: '',
      dateTo: '',

      type: 'buy',
      typeOrder: '',
      selectStatus: '',

      selectFiat: '',
      fiatOptions: [],

      selectCoin: '',
      coinOptions: [],
    }
  },

  computed: {
    ...mapGetters({
      fiatCurrency: 'fiatCurrency/fiatCurrency',
      currency: 'currency/currency',
      orders: 'orders/orders',
    }),
    typeOptions() { return typeOptions },
    typeOrderOptions() { return typeOrderOptions },
    statusOptions() { return statusOptions },
    direction() {
      return store.state.appConfig.isRTL
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchOrdersList: 'orders/fetchOrdersList',
      fetchFiatCurrency: 'fiatCurrency/fetchFiatCurrency',
    }),

    getStatusColor(string) {
      const key = (string || '').toLowerCase().trim()
      switch (key) {
        case 'publish':
          return 'success'
        case 'not_publish':
          return ''
        case 'close':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getStatusName(string) {
      const key = (string || '').toLowerCase().trim()
      switch (key) {
        case 'publish':
          return 'Publish'
        case 'not_publish':
          return 'Not publish'
        case 'close':
          return 'Closed'
        default:
          return key
      }
    },

    createQueryConfig(config) {
      return {
        page: this.orders.page,
        limit: this.orders.limit,

        search: this.searchTerm || undefined,
        cryptoId: this.selectCoin?.id || undefined,
        fiatId: this.selectFiat || undefined,
        direction: this.type || undefined,
        type: this.typeOrder || undefined,
        status: this.selectStatus || undefined,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchOrders(query)
      this.fetchFiatCurrency()
    },

    search() {
      const query = this.createQueryConfig({ search: this.searchTerm })
      return this.fetchOrders(query)
    },
    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchOrders(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchOrders(query)
    },
    changeType(type) {
      const query = this.createQueryConfig({ direction: type })
      return this.fetchOrders(query)
    },
    changeCoin(coin) {
      const query = this.createQueryConfig({ cryptoId: coin?.id || null })
      return this.fetchOrders(query)
    },
    changeFiat(fiat) {
      const query = this.createQueryConfig({ fiatId: fiat })
      return this.fetchOrders(query)
    },

    changeOrder(order) {
      const query = this.createQueryConfig({ type: order })
      return this.fetchOrders(query)
    },
    changeStatus(status) {
      const query = this.createQueryConfig({ status })

      return this.fetchOrders(query)
    },

    fetchOrders(query) {
      return this.waitRequest(() => this.fetchOrdersList(query).catch(this.checkErrors))
    },

    toDeals(row) {
      if (row.countDeals === 0) return
      this.$router.push({ name: 'p2p-deals', query: { orderId: row.id } })
    },
    toUser(id) {
      this.$router.push(`/user/${id}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
}
</style>
