export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Coin',
    field: 'coin',
  },
  {
    label: 'Order type',
    field: 'orderType',
  },
  {
    label: 'Fiat amount ',
    field: 'fiatAmount',
  },
  {
    label: 'Price',
    field: 'price',
  },
  {
    label: 'Crypto Amount',
    field: 'cryptoAmount',
  },
  {
    label: 'Limits',
    field: 'limits',
  },
  {
    label: 'Payment method',
    field: 'paymentMethod',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Count Deals',
    field: 'countDeals',
  },
]
