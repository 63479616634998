export const typeOptions = [
  {
    name: 'Buy',
    value: 'buy',
  },
  {
    name: 'Sell',
    value: 'sell',
  },
]

export const typeOrderOptions = [
  {
    name: 'Fixed',
    value: 'fixed',
  },
  {
    name: 'Market',
    value: 'market',
  },
]

export const statusOptions = [
  {
    name: 'Publish',
    value: 'publish',
  },
  {
    name: 'No publish',
    value: 'not_publish',
  },
  {
    name: 'Closed',
    value: 'close',
  },
]